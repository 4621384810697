import React, { useEffect, useState } from 'react';

const CouncilStones = () => {
  return (
    <React.Fragment>
      staking council stones
    </React.Fragment>
  );
}

export default CouncilStones;